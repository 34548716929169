import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";

import ProgressBar from '../components/ProgressBar';
import RoboxMakerLogo from '../assets/img/RoboxMakerLogo.png';
import EnglandFlag from '../assets/img/flags/England.png';
import SpainFlag from '../assets/img/flags/Spain.png';

const Navbar = (props) => {
  const { IsMainView } = props;

  // Dinamic navbar color change on scroll
  window.onscroll = function () { changeNavbarColor() };
  function changeNavbarColor() {
    // get two navbars, desktop and mobile
    var elements = document.getElementsByClassName('nav-scroll-animated');

    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = "#231F20";
    }
  }

  // i18n language terms
  const [lg, i18n] = useTranslation("global");
  // i18n current language
  const [language, setlanguage] = useState(i18next.language);
  const [activeLink, setActiveLink] = useState("#homepage");

  // Close navbar on mobile when click on a link
  const closeModal = () => {
    setTimeout(() => {
      document.getElementById("closeModal").click();
    }, 500);
  }

  // i18n change language
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
    setlanguage(lng);
    closeModal();
  }

  const handleLinkClick = (link) => {
    console.log(link);
    setActiveLink(link);
  }

  return (
    <>

      {/* Desktop navbar */}

      <nav className="navbar navbar-expand-lg fixed-top pt-4 nav-scroll-animated d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block" style={{ backgroundColor: '#231F20' }} id="nav-desktop">
        <ProgressBar />
        <div className="container-fluid pb-2 px-3">
          <div className="collapse justify-content-between navbar-collapse nav-items-container" id="navbarText">
            <ul className="navbar-nav nav-item nav-item-left mb-2 mb-lg-0" style={{ marginLeft: 20 }}>
              <li className={`nav-item ${!IsMainView && 'd-none'}`} onClick={() => handleLinkClick("#homepage")}>
                <a className={`nav-link active ${activeLink == "#homepage" ? "nav-active-link" : "text-white"}`} aria-current="page" href="#homepage">
                  {lg("navbar.home-link")}</a>
              </li>
              <li className={`nav-item ${!IsMainView && 'd-none'}`} onClick={() => handleLinkClick("#acerca")}>
                <a className={`nav-link ${activeLink == "#acerca" ? "nav-active-link" : "text-white"}`} href="#about" >
                  {lg("navbar.build-link")}</a>
              </li>
              <li className={`nav-item ${!IsMainView && 'd-none'}`} onClick={() => handleLinkClick("#education")}>
                <a className={`nav-link active ${activeLink == "#education" ? "nav-active-link" : "text-white"}`} href="#education">
                  {lg("navbar.education-link")}</a>
              </li>

            </ul>

            <a className='nav-item nav-item-middle ' href={IsMainView ? '#homepage' : '/'} onClick={closeModal}>
              <img src={RoboxMakerLogo} alt="Logo" width="150" height="150" className="d-inline-block align-text-top img-fluid" />
            </a>

            <span className="navbar-text nav-item nav-item-right text-white">

              <div className='d-flex flex-row justify-content-around align-items-center'>
                <a className={`nav-link ${activeLink == "#contact" ? "nav-active-link" : "text-white"}`} href="#contact" >
                  {lg("navbar.contact-link")}</a>
                <a className="custom-button" href="https://www.roboxmaker.com/login" style={{ marginLeft: "30px" }}>
                  {lg("navbar.signin-link")}
                </a>
              </div>
            </span>

          </div>
        </div>
      </nav>

      {/* Mobile navbar 37068c */}

      <nav className="navbar navbar-dark fixed-top pt-3 nav-scroll-animated 
    d-lg-none d-xl-block
    d-xl-none
    d-xxl-none d-xxl-block" style={{ backgroundColor: '#231F20' }} id="nav-mobile">
        <ProgressBar />
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <div>
              <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="mt-2 mb-3 text-center" style={{ marginLeft: 50 }}>
              <a href={IsMainView ? '#homepage' : '/'} onClick={closeModal}>
                <img src={RoboxMakerLogo} alt="Logo" width="150" height="150" className="d-inline-block align-text-top img-fluid" />
              </a>
            </div>
          </div>
          <div className="offcanvas offcanvas-start text-bg-dark" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div className="offcanvas-header" style={{ backgroundColor: '#231F20' }}>
              <img src={RoboxMakerLogo} alt="Logo" width="150" height="150" className="d-inline-block align-text-top img-fluid" />
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" id="closeModal"></button>
            </div>
            <div className="offcanvas-body" style={{ backgroundColor: '#231F20' }}>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ marginLeft: 20 }}>
                <li className={`nav-item ${!IsMainView && 'd-none'}`}>
                  <a className="nav-link active text-white" aria-current="page" href="#homepage" onClick={closeModal}>
                    {lg("navbar.home-link")}</a>
                </li>
                <li className={`nav-item ${!IsMainView && 'd-none'}`}>
                  <a className="nav-link text-white" href="#education" onClick={closeModal}>
                    {lg("navbar.build-link")}</a>
                </li>
                <li className={`nav-item ${!IsMainView && 'd-none'}`}>
                  <a className="nav-link text-white" href="#contact" onClick={closeModal}>
                    {lg("navbar.contact-link")}</a>
                </li>
                <li className={`nav-item ${!IsMainView && 'd-none'}`}>
                  <a className="nav-link text-white" href="https://www.roboxmaker.com/login" onClick={closeModal}>
                    {lg("navbar.signin-link")}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>


    </>
  )
}

export default Navbar
import React from 'react'
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import FormSendedBackground from '../assets/img/backgrounds/FormSended.png';
import BorderCircleCyan from '../assets/img/BorderCircleCyan.png';
import RobotHello from '../assets/img/RobotHello.png';

import { 
    showHidden, 
    rotateCircle,
    topToInitial
 } from '../assets/js/FramerMotionAnimations.js';

const FormSended = (props) => {
    const { Mobile } = props;
    const [lg, i18n] = useTranslation("global");
    return (
        <div>
            <motion.div style={{background: `url(${FormSendedBackground})`, backgroundPosition: "bottom" }} className="section">
                <div className="container text-white" style={{paddingTop: 100, overflow: "hidden"}} >
                    
                    <motion.div className="row d-flex justify-content-center align-items-center" initial="initial" whileInView="animate" viewport={{ once: false }}>
                        <div className="col-12 text-center">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-12">                                                             
                                    <motion.div className="d-flex justify-content-center align-items-center" variants={topToInitial}>
                                        <motion.img src={BorderCircleCyan} className="border-circle" alt="Border Circle" variants={rotateCircle} initial="myhidden" animate="myvisible" />
                                        <motion.img src={RobotHello} className="img-fluid robot-hello" alt="Robot Hello" />
                                    </motion.div>                              
                                </div> 
                                <div className="col-12 col-lg-12 mt-1 pb-4" style={{zIndex:2}}>
                                    <motion.h1 variants={showHidden}>
                                        <b>{lg("form-sended.title")}</b>
                                    </motion.h1>
                                    <motion.span className="font-size-25" variants={showHidden}>
                                        {lg("form-sended.subtitle")}
                                    </motion.span>                                                                      
                                </div>
                                <div className="col-10 col-lg-4 pb-5 mt-0" style={{zIndex:2}}>                                    
                                    <motion.a className="custom-button mt-3" href="/" variants={showHidden}>
                                    <i className="fa-solid fa-house" style={{paddingRight: 5}} />
                                        {lg("form-sended.back-btn")}</motion.a> 
                                </div>
                            </div>                                              
                        </div>
                    </motion.div>
                </div>
            </motion.div>                
            <Mobile>
                <motion.div style={{background: `url(${FormSendedBackground})`, backgroundPosition: "top", backgroundAttachment: "fixed", backgroundSize: "cover", paddingBottom: 100}} />
            </Mobile>                      
        </div>
    )
}

export default FormSended
import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import BigBox from '../assets/img/v2/bg-box_02.png';
import BoxMobile from '../assets/img/v2/box-mobile.png';
import RocketLaptop from "../assets/img/v2/pc-rocket.png";
import BookStars from "../assets/img/v2/books-stars.png";

import FirstIcon from "../assets/img/v2/icon_1.png";
import SecordIcon from "../assets/img/v2/icon_2.png";
import ThirdIcon from "../assets/img/v2/icon_3.png";
import FourthIcon from "../assets/img/v2/icon_4.png";
import FifthIcon from "../assets/img/v2/icon_5.png";
import SixthIcon from "../assets/img/v2/icon_6.png";

import {
    showHidden,
} from '../assets/js/FramerMotionAnimations.js';

const Platform = (props) => {
    // Screen sizes props
    const { Default, Mobile, Tablet, Desktop, FullDesktop } = props;
    // i18n change language
    const [lg, i18n] = useTranslation("global");

    return (
        <div>
            <motion.div className="section" id="about" >

                {/*Header about robox, with images*/}

                <div className='about about-bg-gradient w-100'>
                    <div className="container about-inner-container" style={{ paddingTop: 100, paddingBottom: 100, overflow: "visible" }}>


                        <motion.div className="row d-flex justify-content-center align-items-center" initial="initial" whileInView="animate" viewport={{ once: true }}>

                            <h1 style={{ textAlign: "center", paddingBottom: "80px" }} className='show-resume text-white '>Roboxmaker</h1>

                            {/* Left rotating image and animated typing text legend */}

                            <div className='about-image-container d-flex justify-content-center align-items-center'>

                                <div className='about-desktop-bg'>
                                    <div className='about-background-image' >
                                        <motion.img src={BigBox} alt="Laptop Set" />
                                    </div>
                                </div>

                                <div className='about-mobile-bg'>
                                    <div className='about-background-image' style={{ paddingTop: "80px" }} >
                                        <motion.img style={{ width: "100%" }} src={BoxMobile} alt="Laptop Set" />
                                    </div>
                                </div>

                                {/* Images */}

                                <motion.img className="about-overlay-laptop-image" src={RocketLaptop} alt="Laptop Set" />
                                <motion.img className="about-overlay-books-image" src={BookStars} alt="Laptop Set" />

                                <div className='about-overlay-text-about-robox w-75'>
                                    <h4 className='show-complete text-white font-weight-bold'>Roboxmaker</h4>
                                    <p className='show-complete text-white font-weight-bold' dangerouslySetInnerHTML={{ __html: lg('about.about.first_paragraph') }} />
                                    <br />
                                    <p className='show-complete text-white font-weight-normal' dangerouslySetInnerHTML={{ __html: lg('about.about.second_paragraph') }} />
                                    <p className='show-resume text-white font-weight-bold' dangerouslySetInnerHTML={{ __html: lg('about.about.resume') }} />
                                </div>
                            </div>

                        </motion.div>
                    </div>

                    {/*Advantages section*/}

                    <div className='container section' id='education'>
                        <div className='d-flex flex-column justify-content-center align-items-center about-advantages-inner-container' style={{ paddingTop: 50, paddingBottom: 50 }}>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <h1 className='text-white font-weight-bold'>{lg("about.advantages.title")}</h1>
                                <h5 className='text-white font-weight-normal' style={{ textAlign: "center" }}>{lg("about.advantages.header")}</h5>
                            </div>

                            <div className='about-advantages-bg-img p-5' >
                                <div className='row d-flex about-advantages-container my-4'>

                                    <div className='about-advantages-item about-advantages-item-1 col-lg-4 col-md-6 col-sm-6 col-6 d-flex flex-column'>
                                        <motion.img className='about-advantages-icon' variants={showHidden} src={FirstIcon} alt="Laptop Set" />
                                        <h4 className='text-white font-weight-bold'>{lg("about.advantages.first.title")}</h4>
                                        <p className='about-advatages-item-text  text-white font-weight-normal'>{lg("about.advantages.first.description")}</p>
                                    </div>

                                    <div className='about-advantages-item about-advantages-item-2 col-lg-4 col-md-6 col-sm-6 col-6 d-flex flex-column'>
                                        <motion.img className='about-advantages-icon' variants={showHidden} src={SecordIcon} alt="Laptop Set" />
                                        <h4 className='text-white font-weight-bold'>{lg("about.advantages.second.title")}</h4>
                                        <p className='about-advatages-item-text  text-white font-weight-normal'>{lg("about.advantages.second.description")}</p>
                                    </div>

                                    <div className='about-advantages-item about-advantages-item-3 col-lg-4 col-md-6 col-sm-6 col-6 d-flex flex-column'>
                                        <motion.img className='about-advantages-icon' variants={showHidden} src={ThirdIcon} alt="Laptop Set" />
                                        <h4 className='text-white font-weight-bold'>{lg("about.advantages.third.title")}</h4>
                                        <p className='about-advatages-item-text text-white font-weight-normal'>{lg("about.advantages.third.description")}</p>
                                    </div>

                                    <div className='about-advantages-item about-advantages-item-4 col-lg-4 col-md-6 col-sm-6 col-6 d-flex flex-column'>
                                        <motion.img className='about-advantages-icon' variants={showHidden} src={FourthIcon} alt="Laptop Set" />
                                        <h4 className='text-white font-weight-bold about-advantages-sm-text'>{lg("about.advantages.fourth.title")}</h4>
                                        <p className='about-advatages-item-text text-white font-weight-normal'>{lg("about.advantages.fourth.description")}</p>
                                    </div>

                                    <div className='about-advantages-item col-lg-4 about-advantages-item-5 col-md-6 col-sm-6 col-6 d-flex flex-column'>
                                        <motion.img className='about-advantages-icon' variants={showHidden} src={FifthIcon} alt="Laptop Set" />
                                        <h4 className='text-white font-weight-bold'>{lg("about.advantages.fifth.title")}</h4>
                                        <p className='about-advatages-item-text  text-white font-weight-normal'>{lg("about.advantages.fifth.description")}</p>
                                    </div>

                                    <div className='about-advantages-item about-advantages-item-6 col-lg-4 col-md-6 col-sm-6 col-6 d-flex flex-column'>
                                        <motion.img className='about-advantages-icon' variants={showHidden} src={SixthIcon} alt="Laptop Set" />
                                        <h4 className='text-white font-weight-bold'>{lg("about.advantages.sixth.title")}</h4>
                                        <p className='about-advatages-item-text text-white font-weight-normal'>{lg("about.advantages.sixth.description")}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </motion.div >
        </div >
    )
}

export default Platform
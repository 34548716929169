import React from 'react';
import { useTranslation } from "react-i18next";

import KidBg from '../assets/img/v2/bg-box_01.png';
import Kids from "../assets/img/v2/kids-mobile.png";

import {
    container_delay_childrens_1,
    container_delay_childrens_1_6,
    item_showHidden,
} from '../assets/js/FramerMotionAnimations.js';

const Homepage = (props) => {
    const { Default, Mobile, Tablet, Desktop, FullDesktop } = props;
    const [lg, i18n] = useTranslation("global");

    return (
        <div className="homepage-container home-background-gradient d-flex flex-column justify-content-end align-items-center" >
            <div className="container d-flex justify-content-center align-items-center mx-0 pb-0 section" style={{ maxWidth: "90%" }} id="homepage">
                <div className="home-cols-container d-flex justify-content-center align-items-center w-100" initial="initial" whileInView="animate" viewport={{ once: true }}>

                    {/* Left Headers */}

                    <div className="home-left-side col-10 col-md-6 col-lg-5 d-flex justify-content-center align-items-center" style={{ zIndex: 1 }} variants={container_delay_childrens_1}>
                        <div className="row">
                            <div className="mt-0 small-text-center medium-text-center" variants={item_showHidden}>
                                <>
                                    <br />
                                    <span className="home-title-text" dangerouslySetInnerHTML={{ __html: lg('home.title') }} /> <br />
                                    <span className="home-subtitle-text" dangerouslySetInnerHTML={{ __html: lg('home.subtitle') }} /><br />
                                    <br />
                                    <a className="custom-button" href="#education">{lg("home.know-more-btn")}</a>
                                </>
                            </div>
                        </div>
                    </div>

                    {/* Right floating pieces or laptop-set image */}

                    <div className="home-right-side" variants={container_delay_childrens_1_6}>

                        <div className="home-kid-img-container d-inline-block">
                            <img variants={item_showHidden} src={KidBg} className="home-kid-bg-img w-100 " alt="Laptop Set" />
                            <img variants={item_showHidden} src={Kids} className="home-kid-img w-100" alt="Laptop Set" />
                        </div>

                    </div>
                </div>

            </div>

            {/*Section Header, with info*/}

            <div style={{ font: "Raleway" }} className='about-header d-flex flex-row bg-purple-dark justify-content-around w-100 px-5 py-4'>

                <div className='nav-items-container w-100'>
                    <div className='d-flex flex-row nav-item nav-item-left' style={{maxWidth: "30%"}}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <h4 className='text-white display-1 font-weight-bold' >+5000</h4>
                            <h5 className='text-white about-header-subtitle' >Alumnos</h5>
                        </div>
                        <div className='about-header-description d-flex flex-column justify-content-center align-items-end' style={{ marginLeft: "15px" }}>
                            <p className='text-white' dangerouslySetInnerHTML={{ __html: lg('about.schools') }} />
                        </div>
                    </div>

                    <div className='d-flex flex-row nav-item nav-item-middle' style={{maxWidth: "30%"}}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <h4 className='text-white display-1 font-weight-bold' >+450</h4>
                            <h5 className='text-white about-header-subtitle' >Lecciones</h5>
                        </div>
                        <div className='about-header-description d-flex flex-column justify-content-center' style={{ marginLeft: "15px" }}>
                            <p className='text-white' dangerouslySetInnerHTML={{ __html: lg('about.levels') }} />
                        </div>
                    </div>

                    <div className='d-flex flex-row nav-item nav-item-right' style={{maxWidth: "30%"}}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <h4 className='text-white display-1 font-weight-bold' >14</h4>
                            <h5 className='text-white about-header-subtitle' >Niveles</h5>
                        </div>
                        <div className='about-header-description d-flex flex-column justify-content-center align-items-end' style={{ marginLeft: "15px" }}>
                            <p className='text-white' dangerouslySetInnerHTML={{ __html: lg('about.lectures') }} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Homepage
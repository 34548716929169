import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import RobotsViewerBackground from '../assets/img/backgrounds/RobotsViewer.png';
import RoboxDayBackground from '../assets/img/backgrounds/RoboxDay.png';
import House from '../assets/img/robots/House.glb';

import ListRobots from '../components/ListRobots';

const RobotsViewer = (props) => {
    const { Mobile, Tablet, Desktop , FullDesktop} = props;
    const [lg, i18n] = useTranslation("global");

    const openFullScreenRobotsViewer = () => {
        document.getElementById('modalRobotsPreviewFullscreen').style.display = 'block';
        document.getElementById('nav-desktop').style.zIndex = '0';
        document.getElementById('nav-mobile').style.zIndex = '0';
        document.body.style.overflow = 'hidden';

    }    
    const closeFullScreenRobotsViewer = () => {
        document.getElementById('modalRobotsPreviewFullscreen').style.display = 'none';
        document.getElementById('nav-desktop').style.zIndex = '1';
        document.getElementById('nav-mobile').style.zIndex = '1';
        document.body.style.overflow = 'auto';      
    }    
  return (
    <div>        
        <motion.div style={{background: `url(${RobotsViewerBackground})`, backgroundPosition: "initial", height: 'auto'}} className="section pb-5" id="robots-viewer" >
            <div className="container text-white" style={{paddingTop: 110}}>
                <h2 className="text-center">{lg('robots-viewer.build-your-robot-text')}</h2>
                <div className="row d-flex justify-content-center">           
                    {/* Robot Viewer Window */}
                    <section className="col-11">
                        <div className="row d-flex justify-content-center">                            
                            <model-viewer           
                                style={{background: `url(${RoboxDayBackground})`}}                                        
                                class="robot-preview-container"   
                                id="preview-robot"                                      
                                alt="Preview Robot Model" 
                                src={House} 
                                camera-controls
                                auto-rotate-none
                                orientation="0deg 0 50deg"
                                >   
                                <div className="row d-flex justify-content-end">
                                    <Mobile>
                                        <div className="col-2">
                                            <i className="fa-solid fa-expand fullscreen-icon" onClick={() => openFullScreenRobotsViewer()}></i>    
                                        </div> 
                                    </Mobile> 
                                    <Tablet>
                                        <div className="col-2">
                                            <i className="fa-solid fa-expand fullscreen-icon" onClick={() => openFullScreenRobotsViewer()}></i>    
                                        </div> 
                                    </Tablet>             
                                    <Desktop>
                                        <div className="col-1">
                                            <i className="fa-solid fa-expand fullscreen-icon" onClick={() => openFullScreenRobotsViewer()}></i>    
                                        </div> 
                                    </Desktop>              
                                    <FullDesktop>
                                        <div className="col-1">
                                            <i className="fa-solid fa-expand fullscreen-icon" onClick={() => openFullScreenRobotsViewer()}></i>    
                                        </div> 
                                    </FullDesktop>                                   
                                </div>           
                                <div className="row d-flex justify-content-start robot-visualizer-text">    
                                    <p className='trigger-robot col-9 col-sm-9 col-md-6 col-lg-6'>{lg('robots-viewer.robot-visualizer-text')}</p>
                                </div>                                                                
                                                                                 
                            </model-viewer>                                                                                                                                                     
                        </div>                                                    
                    </section>
                    {/* Robot Viewer Window - Fullscreen Modal*/}
                    <div id="modalRobotsPreviewFullscreen" className="robot-preview-fullscreen-modal ">
                        <div className="robot-preview-container-content" style={{background: `url(${RoboxDayBackground})`, backgroundPosition: "top" }}>                            
                            <model-viewer                                                       
                                class="robot-preview-container-fullscreen"   
                                id="preview-robot-fullscreen"                                      
                                alt="Preview Robot Model" 
                                src={House} 
                                camera-controls
                                auto-rotate-none
                                auto-rotate-delay="5" 
                                rotation-per-second="0deg" 
                                orientation="0deg 0 50deg"
                                >
                                <span className="robot-preview-close-btn" onClick={() => closeFullScreenRobotsViewer()}>
                                    <i className="fa-solid fa-xmark fullscreen-icon"></i>
                                </span>                                 
                            </model-viewer>                                           
                        </div>
                    </div>
                    
                    {/* List of robots for Tablet and Desktop Mode */}
                    <section className="col-12 mt-3 d-none d-sm-block">
                        <h6 className="text-center" style={{marginBottom: -15, marginTop: 30}} dangerouslySetInnerHTML={{__html: lg('robots-viewer.select-a-robot-text')}} />
                        <div className="row d-flex justify-content-center">
                            <ListRobots isMovileDevice={false} />
                        </div>                          
                    </section>

                    {/* List of robots for Mobile mode (horizontal scroll added) */}
                    <section className="col-11 d-block d-sm-none">   
                        <h6 className="text-center" style={{marginTop: 30}} dangerouslySetInnerHTML={{__html: lg('robots-viewer.select-a-robot-text')}} />
                        <div className="horizontal-scroll-container-of-small-robots">
                            <div className="horizontal-row-robots">
                                <ListRobots isMovileDevice={true} />
                            </div>
                        </div>
                    </section>                        
                </div>              
            </div>  
        </motion.div>       
    </div>
  )
}

export default RobotsViewer
import React from 'react';
import RobotsViewer from '../containers/RobotsViewer';
import Navbar from '../components/Navbar';

const VipStack = (props) => {

    const { Mobile, Tablet, Desktop, FullDesktop } = props;

    return (        
        <>
            <Navbar IsMainView={false} />               
            <RobotsViewer Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />                        
        </>
    )
}

export default VipStack;

import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import ScrollToTop from '../assets/js/ScrollToTop';
import Footer from '../components/Footer';

import HomeStack from "../stacks/HomeStack";
import VipStack from '../stacks/VipStack';
import FormSendedStack from '../stacks/FormSendedStack';
import NotFoundStack from "../stacks/NotFound";


function Main() {
	const FullDesktop = ({ children }) => {
		const isFullDesktop = useMediaQuery({ minWidth: 1800, maxWidth: 12000 })
		return isFullDesktop ? children : null;
	}
	const Desktop = ({ children }) => {
		const isDesktop = useMediaQuery({ minWidth: 1150, maxWidth: 1799 })
		return isDesktop ? children : null;
	}
	const Tablet = ({ children }) => {
		const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1149 })
		return isTablet ? children : null;
	}
	const Mobile = ({ children }) => {
		const isMobile = useMediaQuery({ maxWidth: 767 })
		return isMobile ? children : null
	}
	const Default = ({ children }) => {
		const isNotMobile = useMediaQuery({ minWidth: 768 })
		return isNotMobile ? children : null
	}
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Switch>
				<Route exact path="/" component={HomeStack}>
					<HomeStack FullDesktop={FullDesktop} Desktop={Desktop} Tablet={Tablet} Mobile={Mobile} Default={Default} />
				</Route>
				<Route path="/form-sended" component={FormSendedStack}>
					<FormSendedStack FullDesktop={FullDesktop} Desktop={Desktop} Tablet={Tablet} Mobile={Mobile} Default={Default} />
				</Route>
				<Route path="/vip" component={VipStack}>
					<VipStack Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />
				</Route>
				<Route component={NotFoundStack} />
			</Switch>
			<Footer />
		</ BrowserRouter>
	);
}

export default Main;

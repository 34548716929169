import React from 'react';
import Homepage from '../containers/Homepage';
import About from '../containers/About';
import Navbar from '../components/Navbar';
import Contact from "../containers/ContactForm";

const HomeStack = (props) => {

    const { Mobile, Tablet, Desktop, FullDesktop } = props;

    return (
        <>
            {/* <ModalRobotsViewer/> */}
            {/* <ModalInstructions />         */}
            <Navbar IsMainView={true} />
            <Homepage Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />
            <About Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />
            <Contact Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />
            {/* <br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /> */}
        </>
    )
}

export default HomeStack;

import emailjs from '@emailjs/browser';

const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ||  process.env.REACT_APP_EMAILJS_PUBLIC_KEY_DEV;;
const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID || process.env.REACT_APP_EMAILJS_SERVICE_ID_DEV;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || process.env.REACT_APP_EMAILJS_TEMPLATE_ID_DEV;

emailjs.init({
    publicKey: PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
        // Set the limit rate for the application
        id: 'app',
        // Allow 1 request per 10s
        throttle: 10000,
    },
});

export let send = async (name, school, phoneNumber, email, comment) => {

    try {
        let template = {
            name,
            school,
            phoneNumber,
            email,
            comment,
        }

        await emailjs.send(SERVICE_ID, TEMPLATE_ID, template);

        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};
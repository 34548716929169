import React from 'react';
import FormSended from '../containers/FormSended';
import Navbar from '../components/Navbar';

const FormSendedStack = (props) => {

    const { Mobile, Tablet, Desktop, FullDesktop } = props;

    return (        
        <>
            <Navbar IsMainView={false} />     
            <FormSended Mobile={Mobile} Tablet={Tablet} Desktop={Desktop} FullDesktop={FullDesktop} />                        
        </>
    )
}

export default FormSendedStack;

import React from 'react'
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import PlatformBackground from '../assets/img/backgrounds/RoboxDay.png';
import BorderCirclePurple from '../assets/img/BorderCirclePurple.png';
import RobotQuestion from '../assets/img/RobotQuestion.png';
import Navbar from '../components/Navbar';

import { 
    showHidden, 
    rotateCircle,
    bottomToInitial
 } from '../assets/js/FramerMotionAnimations.js';

const NotFound = () => {
    const [lg, i18n] = useTranslation("global");
    return (
        <div>
            <Navbar />
            <motion.div style={{background: `url(${PlatformBackground})`, backgroundPosition: "bottom" }} className="section">
                <div className="container text-white" style={{paddingTop: 100, overflow: "hidden"}} >
                    
                    <motion.div className="row d-flex justify-content-center align-items-center" initial="initial" whileInView="animate" viewport={{ once: false }}>
                        <div className="col-12 text-center">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-12">                                                             
                                    <motion.div className="d-flex justify-content-center align-items-center" variants={bottomToInitial}>
                                        <motion.img src={BorderCirclePurple} className="border-circle" alt="Border Circle" variants={rotateCircle} initial="myhidden" animate="myvisible" />
                                        <motion.img src={RobotQuestion} className="img-fluid robot-question" alt="Robot Question" />
                                    </motion.div>                              
                                </div> 
                                <div className="col-12 col-lg-12 mt-1 pb-4" style={{zIndex:2}}>
                                    <motion.h1 variants={showHidden}>
                                        <b>{lg("not-found.title")}</b>
                                    </motion.h1>
                                    <motion.span className="font-size-25" variants={showHidden}>
                                        {lg("not-found.subtitle")}
                                    </motion.span>                                                                      
                                </div>
                                <div className="col-10 col-lg-4 pb-5 mt-0" style={{zIndex:2}}>                                    
                                    <motion.a className="custom-button-pink mt-3" href="/" variants={showHidden}>
                                    <i className="fa-solid fa-house" style={{paddingRight: 5}} />
                                        {lg("not-found.back-btn")}</motion.a> 
                                </div>
                            </div>                                              
                        </div>
                    </motion.div>
                </div>
            </motion.div>                                  
        </div>
    )
}

export default NotFound
import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { send } from '../services/email.js';

import Logo from '../assets/img/v2/robox-logo_2.png';

import {
    showHidden,
    rotateCircle,
    leftTopToBottom,
    rightToInitial
} from '../assets/js/FramerMotionAnimations.js';

const mensajesModal = [
    {
        title: "Correo enviado",
        message: "Su mensaje ha sido enviado con exito."
    },
    {
        title: "Error",
        message: "Ha ocurrido un error al enviar el correo."
    }
]

const Platform = (props) => {
    // Screen sizes props
    const { Mobile, Tablet, Desktop, FullDesktop } = props;
    // i18n change language
    const [lg, i18n] = useTranslation("global");

    const [name, setName] = useState("");
    const [school, setSchool] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [modalMessaje, setModalMessaje] = useState(mensajesModal[0]);

    const ContactInfo = () => {
        return (
            <>
                <h1 >{lg("contact.title")}</h1>

                <p>
                    {lg("contact.description")}
                </p>
            </>
        );
    }

    return (
        <div className='section' id="contact" >

            {showModal &&
                <div class="modal show" style={{ display: 'block', backgroundColor: "rgba(0, 0, 0, 0.7)" }} >
                    <div class="modal-dialog" >
                        <div class="modal-content form-modal-style">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">{modalMessaje.title}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>{modalMessaje.message}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={() => setShowModal(false)} class="btn btn-primary">Entendido</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="contact-container contact-bg-gradient d-flex justify-content-around align-items-center flex-row text-white py-5"  >

                {/*Left Section*/}

                <div className='contact-address-container d-flex flex-column justify-content-center align-items-center '>
                    <div className='contact-mobile-layout' style={{ maxWidth: "300px" }}>
                        <div className='contact-info-hidde'>
                            <ContactInfo />
                        </div>

                        <div className='contact-info-hidde divider'></div>

                        <motion.img className='contact-info-hidde py-4' style={{ maxWidth: "300px" }} variants={showHidden} src={Logo} alt="Laptop Set" />

                        <div className='contact-info-hidde divider' ></div>

                        <div className='address' >
                            <h5 className='pt-3'>
                                {lg("contact.adress_title")}
                            </h5>

                            <p className=''>
                                {lg("contact.adress")}
                            </p>
                        </div>

                        <div className='phone'>
                            <h5 className='pt-2'>
                                {lg("contact.phone_title")}
                            </h5>
                            <p className=''>
                                {lg("contact.phone")}
                            </p>
                        </div>

                        <div className='email'>
                            <h5 className='pt-2'>
                                {lg("contact.email_title")}
                            </h5>

                            <p className=''>
                                {lg("contact.email")}
                            </p>
                        </div>

                    </div>
                </div>

                {/*Right Section*/}

                <div style={{ maxWidth: "550px" }} className='contact-form-container d-flex flex-column justify-content-center'>

                    <div className='show-on-mobile-only d-flex flex-column justify-content-center align-items-center contact-form-header-mobile-only' >
                        <ContactInfo />
                    </div>

                    <h1 dangerouslySetInnerHTML={{ __html: lg('contact.form.title') }}></h1>

                    <div>

                        <form className='row'>
                            <div className="form-group col">
                                <label className='text-white font-weight-bold' htmlFor="name">{lg("contact.form.name")}</label>
                                <textarea value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="message" rows="2" ></textarea>
                            </div>

                            <div class="contact-mobile-space w-100 pb-3"></div>

                            <div className="form-group col">
                                <label className='font-weight-bold' htmlFor="school">{lg("contact.form.school")}</label>
                                <textarea value={school} onChange={(e) => setSchool(e.target.value)} className="form-control" id="message" rows="2" ></textarea>
                            </div>

                            <div class="w-100 pb-3"></div>

                            <div className="form-group col">
                                <label className='font-weight-bold' htmlFor="school">{lg("contact.form.telefono")}</label>
                                <textarea value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" id="message" rows="2" ></textarea>
                            </div>

                            <div class="contact-mobile-space w-100 pb-3"></div>

                            <div className="form-group col">
                                <label className='font-weight-bold' htmlFor="email">{lg("contact.form.email")}</label>
                                <textarea value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="message" rows="2" ></textarea>
                            </div>

                            <div class="w-100 pb-3"></div>

                            <div className="form-group col">
                                <label className='font-weight-bold' htmlFor="message">{lg("contact.form.comentario")}</label>
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" id="message" rows="4" ></textarea>
                            </div>

                            <div class="w-100 pb-5"></div>

                            <div style={{ width: "80%" }} onClick={async () => {
                                let result = await send(name, school, phoneNumber, email, comment);

                                setShowModal(true);

                                if (result) {
                                    setName("");
                                    setSchool("");
                                    setPhoneNumber("");
                                    setEmail("");
                                    setComment("");

                                    setModalMessaje(mensajesModal[0]);
                                } else {
                                    setModalMessaje(mensajesModal[1]);
                                }

                            }} className="btn btn-primary w-100">{lg("contact.form.send")}</div>
                        </form>
                    </div>
                </div>

            </div >
        </div>
    )
}

export default Platform